import styled from 'styled-components';

export const StyledBanner = styled.div`
  width: 100%;
  margin: 0 auto;
  //max-height: 500px;
  background: transparent;

  .banner {
    --content-background-color: transparent;
    z-index: 0;
  }

  .banner.top {


  }

  .banner.top .awssld__wrapper {
    min-height: 300px;
  }

  .awssld__container {
    padding-bottom: 0;
    height: 100%;
  }

  #bannerBlock {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 400px;
    z-index: 2;
  }

  h1 {
    font-size: 1.5rem;
    margin: 0;
    color: ${({theme}) => theme.primaryLight};
  }

  h2 {
    font-size: 1.5rem;
    margin: 0;
    color: ${({theme}) => theme.primaryDark};
  }

  button {
    margin: 0;
  }

  nav.awssld__bullets button {
    margin: 0.3rem;
  }

  .bottomBlurb {
    max-width: 290px;
    min-height: 100px;
  }

  .bannerText {
    max-height: 150px;
  }

  .banner.top .gatsby-image-wrapper img {
    object-fit: cover!important;
    height: inherit!important;
  }

  .banner.bottom, banner.reviews {
    max-width: 1200px;
    margin: 0 auto;
  }

  .banner.bottom #bottomContent {
    max-width: 900px;
    margin: 0 auto;
  }

  .banner.bottom .flatButton {
    width: 290px;
    position: absolute;
    bottom: 0;
  }

  .flatButton a {
    color: ${({theme}) => theme.primaryDark};
  }

  .banner.bottom h2 {
    margin-bottom: 2rem;
  }

  .awssld.reviews {
    --content-background-color: ${({theme}) => theme.primaryLight};
    height: 300px;
  }

  .reviews .awssld__content {
    display: flex;
  }

  .reviews .awssld__content div {
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem;
  }

  nav.awssld__bullets {
    bottom: 1rem;
  }

  button.awssld__bullets--active {
    background: ${({theme}) => theme.primaryDark}!important;
  }

  .awssld__content {
    display: inherit;

  }

  .awssld__content div {
    width: 100%;
    z-index: 1;
  }

  .awssld__bullets .awssld__bullets--active {
    transform: scale(1.0);
    opacity: 1;
  }

  .awssld__controls__arrow-left, .awssld__controls__arrow-right {
    height: 2rem;
  }

  .awssld__controls__arrow-left:after, .awssld__controls__arrow-right:after, .awssld__controls__arrow-left:before, .awssld__controls__arrow-right:before {
    max-height: 2rem;
    border-radius: 4rem;
  }

  .awssld__bullets button {
    height: 10px;
    width: 10px;
    background: ${({theme}) => theme.primaryWhite};
    opacity: 0.7;
  }

  .awssld__timer.awssld__timer--animated.awssld__timer--run {
    display: none;
  }

  nav.awssld__bullets {
    z-index: 5;
  }

  .loader {
    border: 5px solid ${({ theme }) => theme.primaryWhite}; /* Light grey */
    border-top: 5px solid ${({ theme }) => theme.primaryDark}; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: 2rem auto;
  }

  @media(max-width:576px){
    .banner.top .awssld__wrapper {
      height: 60vh;
    }
    .banner.top.desktop {
      display: none;
    }
    .banner.top.mobile {
      height: 55vh;
    }
    .awssld__controls {
      visibility: visible;
    }
    .awssld__controls button.awssld__prev {
      left: 0;
    }
    .awssld__controls button.awssld__next {
      right: 0;
    }
    .banner.bottom .awssld__controls button.awssld__prev {
      top: calc(30% - (0.5 * var(--control-button-height)));
    }
    .banner.bottom .awssld__controls button.awssld__next {
      top: calc(30% - (0.5 * var(--control-button-height)));
    }
    .topFooter p {
      -webkit-line-clamp: 5;
    }
    .gatsby-image-wrapper {
      height: 60vh;
    }
    
    .banner.top.mobile img {
      object-fit: contain!important;
      object-position: center 1rem!important;
    }
    .bottomBlurb p {
      margin-bottom: 3rem;
    }
    .bottomBlurb, .banner.bottom .flatButton {
      max-width: 100%;
      width: 100%;
    }
  }	
  
  @media(min-width: 577px) and (max-width: 767px){
    
    .banner.top .awssld__wrapper {
      height: 40vh;
    }
    .banner.top.mobile {
      display: none;
    }
    
  }	
  
  @media(min-width: 768px) and (max-width:1023px) {
    .banner.top.mobile {
      display: none;
    }
    .banner.top .awssld__wrapper {
      height: 30vh;
    }
  }	

  @media(width: 1024px){
    .banner.top.mobile {
      display: none;
    }
    .banner.top .awssld__wrapper {
      height: 50vh;
    }
  }
  
  @media(min-width: 1025px){
    .banner.top.mobile {
      display: none;
    }
    .banner.top .awssld__wrapper {
      height: 75vh;
    }
  }

  @media(min-width: 577px) and (max-width: 1024px){
    #bottomContent .gatsby-image-wrapper {  
      margin-right: 10px;
    }
  }

`;