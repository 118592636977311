import styled from 'styled-components';

export const StyledHeader = styled.nav`

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: transparent;
  z-index: 999999;

  .topHeader {
    width: 250px; 
    margin-left: auto;
    margin-right: auto;
  }

  .desktopMenu {
    max-width: 1000px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    text-align: center;
    background: ${({ theme }) => theme.primaryDark};
    color: white;
    padding: 0;
  }

  .desktopMenu .menuContainer {
    position: relative;
  }

  .desktopMenu .mainMenuItems {
    right: 0;
  }

  .mobileMenu .mainMenuItems {
    left: 0;
  }

  @media(min-width: 667px){
    .mobileMenu {
      display: none;
    }
  }
  
  @media(max-width: 666px){
    .menuTitle {
      display: none;
    }
    .desktopMenu {
      display: none;
    }
    .mobileMenu {
      display: grid;
      grid-template-columns: 25% 50% 25%;
      align-items: center;
    }
  }

  .logo img {
    width: 70%;
  }

  .desktopMenu .logo img {
    padding: 1rem 0;
  }

  .mobileMenu a.logo {
    padding: 0;
  }

  .mobileMenu {
    background: ${({ theme }) => theme.primaryDark}
  }

  .mobileMenu .logo {
    text-align: center;
  }

  .mobileMenu a {
    padding: 0.5rem;
  }

  .menu2 {
    background: ${({ theme }) => theme.secondaryDark}
  }

  .desktopMenu .mainMenuItems, .desktopMenu .secondMenuItems {
    padding: 0;
  }
  .mobileMenu .secondMenuItems {
    width: 100vw;
    left: 0;
  }

  .desktopMenu .subMenu.text {
    display: none;
  }

  .mobileMenu .subMenu.true {
    font-weight: 600;
  }

  .mobileMenu .menuLink {
  }

  .mobileMenu .menu2 {
    color: ${({ theme }) => theme.primaryDark};
    border-right: 1px solid grey;
  }

  .mobileMenu .menu3 {
    color: #d61f27;
  }

  .mobileMenu .menu2, .mobileMenu .menu3, .subMenu.text {
    background: white;
  }

  .subMenu.text {
    padding: 0.5rem;
    border-bottom: 3px solid ${({ theme }) => theme.primaryDark};
  }

  .mobileMenu .menu2.menuLink.true {
    //border-bottom: 3px solid ${({ theme }) => theme.primaryDark};
  }

  .mobileMenu .menu3.menuLink.true {
    //border-bottom: 3px solid #d61f27;
  }

  .menu3 {
    background: #d61f27;
  }

  .red a:hover, .red a.active  {
    background: #d61f27!important;
  }

  .blue a:hover, .blue a.active {
    background: ${({ theme }) => theme.secondaryDark}!important;
  }

`;