import React, { useState, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import { theme } from '../components/css/theme';
import { Burger, Menu } from '../components';
import { StyledHeader } from "./css";
import { Header } from '../components/theme';

import { useOnClickOutside } from '../hooks';

function Nav({ page }) {

  return (
    <Header />

  )

}

export default Nav
