import styled from 'styled-components';

export const StyledAccordian = styled.div`
  position: relative;
  display: inline-block;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  padding: 0;
  z-index: 1;
  width: 100%;

  .true {
    background: #96979a;
    color: ${({ theme }) => theme.primaryWhite};
    border: 1px solid #eee;
  }

  button {
    margin-bottom: 0.2rem;


  }
`;