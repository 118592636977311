import styled from 'styled-components';

export const StyledInput = styled.div`

  div {
    border: none;
  }

  span {
    color: ${({theme}) => theme.primaryDark};
  }
  
  label {
    display: block;
    text-align: center;
    margin-bottom: 5px;
  }

  input, textarea {
    padding: 0.5rem;
    width: 100%;
    margin-bottom: 1rem;
  }

  i {
    display: none;
  }
`;