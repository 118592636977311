var { parsePhoneNumberFromString } = require('libphonenumber-js');

exports.formatTelephone = (number = process.env.GATSBY_COMPANY_TEL_1) => {

  if (number === null) {
    return false;
  }

  //To ensure the first symbol is a +

  if (number.charAt(0) === '0') {
    number = `+${number.replace(0, 27)}`;
  }

  if (number.charAt(0) !== '+') {
    number = `+${number}`;
  }

  const phoneNumber = parsePhoneNumberFromString(number);

  if (!phoneNumber) {
    return false;
  } else {
    return phoneNumber.formatNational();
  }

}