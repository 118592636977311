import styled from 'styled-components';

export const StyledToggle = styled.div`

h6 {
  cursor: pointer;
  text-transform: uppercase;
}

h5 {
  text-transform: uppercase;
  margin: 0;
}

.viewBtn {
  border: solid 1px;
  padding: 5px 10px;
  margin-right: 5px;
}

padding: 1rem 2rem;
background: white;
position: absolute;
top: -2rem;
min-width: 300px;

`;