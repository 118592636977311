import styled from 'styled-components';

export const StyledMenuSide = styled.div`

  z-index: 99999;

  position: ${({tag}) => tag === "second" ? 'relative' : 'inherit'};
  
  .mainMenuItems, .secondMenuItems, .thirdMenuItems {
    visibility: ${({ open }) => open ? 'visible' : 'hidden'};
    opacity: ${({ open }) => open ? 1 : 0};
    max-height: ${({ open }) => open ? '1000px' : '0px'};
    -webkit-transition: height 0.3s ease-in;
    -moz-transition: height 0.3s ease-in;
    -o-transition: height 0.3s ease-in;
    -ms-transition: height 0.3s ease-in;
    transition: height 0.3s ease-in;
    position: absolute;
    z-index: 1;
    background: white;
  }

  .mobileMenuDropdown {
    visibility: ${({ open1 }) => open1 ? 'visible' : 'hidden'};
    opacity: ${({ open1 }) => open1 ? 1 : 0};
    max-height: ${({ open1 }) => open1 ? '1000px' : '0px'};
    -webkit-transition: height 0.3s ease-in;
    -moz-transition: height 0.3s ease-in;
    -o-transition: height 0.3s ease-in;
    -ms-transition: height 0.3s ease-in;
    transition: height 0.3s ease-in;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    background: white;
    width: 100vw;
    height: 3rem;
    text-align: center;
  }

  @media(min-width: 667px){
    .mainMenuItems {
      max-width: calc(800px * 0.5);
      width: calc(100vw * 0.5);  
    }
    .secondMenuItems {
      max-width: calc(800px * 0.5);
      width: calc(100vw * 0.5); 
    }
  }

  @media(max-width: 666px){
    .mainMenuItems, .secondMenuItems {
      width: 100vw; 
    }
    .secondMenuItems {
      width: 100vw;
      margin-top: 0.5rem;
    }
    .menuImage {
      display: none;
    }
  }

  .secondMenuItems, .thirdMenuItems {
    right: 0;
  }


  .menuImage {
    width: 150px!important;
    height: auto!important;
    margin-top: 1rem;
  }

  .mobileMenu img.menuImage {
    display: none;
  }

  

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }

  li {
    list-style: none;
    text-align: left;
  }

  span {
    text-align: right;
  }

`;