import styled from 'styled-components';

export const StyledBlog = styled.div`

  
  //margin-top: 5rem;

  p {
    margin-bottom: 2rem;
  }

  .readTime {
    text-transform: uppercase;
    margin-bottom: 2rem;
    font-weight: 600;
    font-family: ${({ theme }) => theme.secondaryTypeface};
  }

  margin-bottom: 2rem;

  #blogBlock {
    position: relative;
    bottom: 0;
    left: 0;
    background: white;
  }

  .blogCategory {
    text-transform: Capitalize;
  }

  .blogInfo h5 {
    margin-bottom: 5px;
    text-transform: Capitalize;
  }

  @media(min-width: 667px){
    .blogText {
      column-count: 2;
      column-gap: 3rem;
    }

    .blogText p {
      margin: 0 0 15px 0;
    }
  }

  .gatsby-image-wrapper {
    max-height: 500px;
  }

  img {
    object-fit: contain!important;
  }

  .related img {
    object-fit: cover!important;
    object-position: top center!important;
  }

`;
