import React from 'react';

import { FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import { WhatsappShareButton, EmailShareButton } from "react-share";

import { StyledShare } from "../css"

const Share = ({ socialConfig }) => {

  return (
    <StyledShare>

      <h4>Share</h4>

      <WhatsappShareButton url={socialConfig.config.url} >
        <FaWhatsapp />
      </WhatsappShareButton>

      <EmailShareButton url={socialConfig.config.url} subject={`Check out ${socialConfig.config.title}`} body={`I think you should have a look at this property`} separator="---">
        <FaEnvelope />
      </EmailShareButton>

    </StyledShare>
  )
}


export default Share;