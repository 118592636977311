import styled from 'styled-components';

export const StyledBasicTab = styled.div`

  padding: 1em 0 1em 0.5rem;
  background: ${({theme}) => theme.primaryWhite};

  h5 {
    color: ${({ theme }) => theme.primaryLight};
    margin: 7px 0;
    text-transform: uppercase;
    font-weight: 600;
  }

  span {
    background: ${({ theme }) => theme.primaryDark};
    padding: 1rem;
    display: block;
    color: ${({ theme }) => theme.primaryLight};
  }

  h6 {
    margin: 0;
    display: inline-block;
    color: ${({ theme }) => theme.primaryDark};
  }

  p {
    margin: 2px;
    color: ${({ theme }) => theme.primaryLight};
  }

  p:first-letter { text-transform: uppercase;}

  img {
    max-width: 200px;
  }

`;
