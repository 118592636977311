import styled from 'styled-components';

export const StyledShare = styled.div`



svg {
  height: 1.5rem;
  width: 1.5rem;

  &:hover {
    color: ${({ theme }) => theme.primaryLight};
  }
  
}

.shareButton {
  display: block;
  margin-bottom: 1rem;
}

button {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  grid-column-gap: 0.5rem;
  background-color: transparent;
  border: none;
  padding: 0px;
  font: inherit;
  color: inherit;
  cursor: pointer;
}

p {
  text-align: left;
}

h4 {
  margin-bottom: 1rem;
}
`;