import styled from 'styled-components';

export const StyledImage = styled.div`

  

  .loader {
    border: 5px solid ${({ theme }) => theme.primaryWhite}; /* Light grey */
    border-top: 5px solid ${({ theme }) => theme.primaryDark}; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: 2rem auto;
  }

  @media (max-width: 520px){
    .awssld__controls {
      visibility: initial!imporyant;
    }
  }

  img {
    max-width: 100%;
  }
  
`