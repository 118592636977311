import React from 'react';

import { number, func } from 'prop-types';
import { StyledCarousel } from '../css';

const Carousel = ({ open, setOpen }) => {
  return (

    <div>

      <StyledCarousel>

        <button onClick={(e) => { e.preventDefault(); setOpen(open === 1 ? 0 : 1); }}>

          {`>`}

        </button>

        <button onClick={(e) => { e.preventDefault(); setOpen(open === 0 ? 1 : 0); }}>

          {`<`}

        </button>

      </StyledCarousel>

    </div>

  )
}
Carousel.propTypes = {
  open: number.isRequired,
  setOpen: func.isRequired,
};

export default Carousel;