import styled from 'styled-components';

export const StyledVideo = styled.div`

  max-height: calc(100vh - 80px);

  .react-player {
    height: 400px!important;
  }
  
`