export const pageview = url => {

  if (typeof window !== "undefined") {

    window.gtag('config',
      process.env.GATSBY_GOOGLE_ANALYTICS, {
      page_location: url
    });
    window.gtag('config',
      process.env.GATSBY_GOOGLE_AD_TAG, {
      page_location: url
    });

  }

}

export const event = ({ action, category, label }) => {

  if (typeof window !== "undefined") {

    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      event_value: 1
    })

  }

}