export const theme = {
  primaryTypeface: 'Open Sans',
  secondaryTypeface: 'Barlow',
  primaryDark: '#0b203b',
  primaryLight: '#ced1d4',
  primaryWhite: '#ffffff',
  primaryFont: process.env.GATSBY_PRIMARY_COLOUR,
  primaryFontLight: '#ffffff',
  primaryHover: process.env.GATSBY_PRIMARY_COLOUR,
  secondaryDark: '#163656',
  mobile: '666px',
  navStyle: 'other', //side or horizontal
  navBackground: '#b8beca',
  primaryButton: '#ffffff',
  siteWidth: '1200px',
  siteMargin: '0 auto',
  breadcrumbSeperator: "/"
}