import styled from 'styled-components';

export const StyledPrevNext = styled.div`

  border-top: solid 1px ${({ theme }) => theme.secondaryDark};
  background: ${({ theme }) => theme.primaryLight};
  margin-top: 6rem;
  padding: 3rem 1rem;
  text-align: center;

  a {
    display: block;
    color: ${({ theme }) => theme.primaryWhite};
  }

  .profileImageContainer {
    display: block;
    height: 150px;
    overflow: hidden;
  }
`;
