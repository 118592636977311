import styled from 'styled-components';

export const StyledInfoTab = styled.div`

  padding: 1em 0 1em 0.5rem;
  background-color: ${({theme}) => theme.primaryWhite};

  .details {
    min-height: 83px;
  }

  h5 {
    color: ${({ theme }) => theme.primaryLight};
    margin: 7px 0 8px 0;
    text-transform: uppercase;
    font-weight: 600;
  }

  h6 {
    margin: 0;
    display: inline-block;
    color: ${({ theme }) => theme.primaryDark};
  }

  p {
    margin: 2px;
    display: inline-block;
    color: ${({ theme }) => theme.primaryLight};
  }

  p:first-letter { text-transform: uppercase;}

  span {
    display: block;
  }

`;
