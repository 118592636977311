import React from 'react';
import { func, number } from 'prop-types';

import { StyledAccordian, Grid32 } from '../css';

const Accordian = ({ open, setOpen, ext, int, fp, vid }) => {

  return (
    <div>

      <StyledAccordian>

        <Grid32>

          {
            ext && (
              <button className={`flatButton ${open === 0}`} onClick={(e) => { e.preventDefault(); setOpen(0); }}>Exterior</button>
            )
          }

          {
            int && (
              <button className={`flatButton ${open === 1}`} onClick={(e) => { e.preventDefault(); setOpen(1); }}>Interior</button>
            )
          }

          {
            fp && (
              <button className={`flatButton ${open === 2}`} onClick={(e) => { e.preventDefault(); setOpen(2); }}>Floor plans</button>
            )
          }

          {
            vid && (
              <button className={`flatButton ${open === 3}`} onClick={(e) => { e.preventDefault(); setOpen(3); }}>Video</button>
            )
          }

        </Grid32>

      </StyledAccordian>

    </div>

  )
}
Accordian.propTypes = {
  open: number.isRequired,
  setOpen: func.isRequired,
};

export default Accordian;