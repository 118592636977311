import React from "react";

import { Grid61, StyledInfoTab } from "../css";

var formatCurrency = require("cre-plugin/src/components/utils/formatter").formatCurrency;
var formatCurrencyGLA = require("cre-plugin/src/components/utils/formatter").formatCurrencyGLA;
var formatGLA = require("cre-plugin/src/components/utils/formatter").formatGLA;

var priceRange = require("cre-plugin/src/components/utils/priceGlaData").priceRange;
var glaRange = require("cre-plugin/src/components/utils/priceGlaData").glaRange;

const zeroPad = (num, places) => String(num).padStart(places, '0')

const InfoTab = ({ info, open, deal, type, i }) => {

  //Property tabs
  if (type === "property") {

    //To let
    if (deal === "ToLet") {
      return (

        <StyledInfoTab>

          {
            open === 0 && (
              <div>



                  {/* <p className="itemCount">{zeroPad(i + 1, 2)}</p> */}

                  <div className="details">

                    <h6>{info[0].property_category} to let</h6>
                    <h5>{info[0].property_name}</h5>
                    <h6>{info[0].suburb}</h6>

                  </div>



              </div>
            )
          }
          {
            open === 1 && (

              <div>

                

                  {/* <p className="itemCount">{zeroPad(i + 1, 2)}</p> */}

                  <div className="details">

                    <span>
                      <h6>Price:</h6><p> {formatCurrencyGLA(priceRange(info).gross_price_1, priceRange(info).gross_price_2)}</p>
                    </span>
                    <span>
                      <h6>GLA:</h6><p> {formatGLA(glaRange(info).gla_1, glaRange(info).gla_2)}</p>
                    </span>
                    <span>
                      <h6>From:</h6><p> {formatCurrency(priceRange(info).priceFrom)} /month</p>
                    </span>
                    {/* <span>
                      <h6>Units:</h6><p> {info.length > 1 ? info.length + ` units available` : info.length + ` unit available`}</p>
                    </span> */}

                  </div>

                

              </div>

            )
          }
        </StyledInfoTab>

      )
    }

    //For sale
    if (deal === "ForSale") {
      return (

        <StyledInfoTab>

          {
            open === 0 && (
              <div >

                

                  {/* <p className="itemCount">{zeroPad(i + 1, 2)}</p> */}

                  <div className="details">

                    <h6>{info.property_category} for sale</h6>
                    <h5>{info.property_name}</h5>
                    <h6>{info.suburb}</h6>

                  </div>

                

              </div>
            )
          }
          {
            open === 1 && (

              <div>

                

                  {/* <p className="itemCount">{zeroPad(i + 1, 2)}</p> */}

                  <div className="details">

                    <span>
                      <h6>Price:</h6><p> {formatCurrency(priceRange([info]).gross_price_1)}</p>
                    </span>
                    <span>
                      <h6>GLA:</h6><p> {formatGLA(glaRange([info]).gla_1)}</p>
                    </span>
                    <span>
                      <h6>Type:</h6><p> {(info.property_type.charAt(0).toUpperCase() + info.property_type.slice(1)).replace("_", " ")} for sale</p>
                    </span>
                  </div>

                

              </div>

            )
          }

        </StyledInfoTab>

      )
    }

  }
}

export default InfoTab