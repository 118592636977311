import styled from 'styled-components';

export const StyledServices = styled.div`

  grid-row-gap: 0rem;

  h1 {
    line-height: 100%;
  }

  h4 {
    margin-top: 7px;
    margin-bottom: 0.5rem;
    font-size: 1.75rem;
  }

  h3 {
    color: ${({ theme }) => theme.primaryLight};
  }  

  .servicesIntro {
    text-align: right;
  }

  h4.servicesIntro {
    margin: 10px;
  }

  .servicesIntro p {
    margin: 2px;
  }

  .stripedBackground {
    margin-top: 3rem;
    position: relative;
    height: 100%;
    overflow: hidden;
    background-color: white;
  }

  .stripedBackground:before {
    content: '';
    position: absolute;
    left: -80px;
    width: 100%; 
    height: 200%; 
    background-color: ${({ theme }) => theme.primaryWhite};
    bottom: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    background: repeating-linear-gradient( 90deg,transparent,transparent 4px, transparent 10px, ${({ theme }) => theme.primaryDark} 12px );
  }

  hr.servicesHr {
    transform: rotate(45deg);
    height: 3rem;
    width: 6px;
    background: black;
    border: none;
    border-radius: 4px;
  }

  @media(max-width:576px){
    .stripedBackground, hr.servicesHr {
      display: none;
    }
    .servicesIntro {
      text-align: left;
      margin-left: 0!important;
    }
  }	

  @media(min-width: 577px) and (max-width: 768px){
    .serviceColumn {
      grid-column: 2;
    }
  }

`;
