import styled from 'styled-components';

export const StyledModal = styled.div`



  h3 span {
    color: ${({ theme }) => theme.primaryWhite };
  }

  input, textarea {
    width: 100%;
    background-color: ${({ theme }) => theme.primaryWhite };
  }

  .modal-main {
    padding: 1rem;
    position:fixed;
    background: ${({ theme }) => theme.primaryDark };
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    max-height: 100vh;
    overflow-y: auto;
    max-width: 400px;
  }

  .display-block {
    display: block;
    z-index: 999;
    position: fixed;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  .display-none {
    display: none;
  }

  .modalClose {
    text-align: right;
  }

  .filterToggleClose {
    border: 2px solid ${({ theme }) => theme.primaryWhite };
  }

  @media(max-width: 666px){
    .modal-main {
      left: 0%;
      margin: 10px;
      transform: translate(0%,-50%);
    }
  }
`