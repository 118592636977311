import styled from 'styled-components';

export const Styled404 = styled.div`

  background: #0d1824;

  div {
    width: 80vw;
    max-width: 700px;
    padding-top: 3rem;
    margin: auto;
  }

  img {
    max-width: 100%;
  }

`
;