import styled from 'styled-components';

export const StyledPerson = styled.div`

  h6 {
    margin-bottom: 2rem;
  }

  margin-bottom: 2rem;

  #personBlock {
    position: relative;
    bottom: 0;
    left: 0; 
    background: white;
  }

  .writeUp {
    color: #96979a;
  }

`;
