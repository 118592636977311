import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEOProperty = ({ keywords, best_image, street_address, suburb, city, latitude, longitude, category, url, property_name, marketing_heading, marketing_description, id, deal, price, contact_name, contact_email, contact_telephone }) => {

  const { site } = useStaticQuery(query);

  const {
    siteLocale,
    siteName,
    siteTitle,
    siteKeywords,
    siteDescription,
    siteUrl,
    siteImage,
    twitterUsername,
  } = site.siteMetadata;

  const SEO = {
    siteLocale: siteLocale,
    siteName: siteName,
    title: property_name + " - " + category + " " + deal + " in " + suburb || siteTitle,
    description: marketing_description || siteDescription,
    image: best_image || siteImage,
    keywords: keywords || siteKeywords,
    url: process.env.GATSBY_WEBSITE_URL + url || siteUrl,

    //property specific
    street_address: street_address,
    suburb: suburb,
    city: city,
    latitude: latitude,
    longitude: longitude,
    category: category,
    marketing_heading: marketing_heading || property_name + " - " + category + " " + deal + " in " + suburb,
    id: id,
    deal: deal,
    price: price,
    contact_name: contact_name || process.env.GATSBY_COMPANY_NAME,
    contact_email: contact_email || process.env.GATSBY_COMPANY_EMAIL,
    contact_telephone: contact_telephone || process.env.GATSBY_COMPANY_TEL_1
  }

  //Property page
  var jsonld = {
    __html: `{
      "@context": "http://schema.org",
      "@graph": [
        {
          "@type": "RealEstateListing",
          "primaryImageOfPage": {
            "@type": "ImageObject",
            "url": "${SEO.image}"
          },
          "contentLocation": {
            "@type": "Place",
            "address": "${SEO.street_address}, ${SEO.suburb}, ${SEO.city}",
            "latitude": ${SEO.latitude},
            "longitude": ${SEO.longitude},
            "photo": {
              "@type": "ImageObject",
              "url": "${SEO.image}"
            }
          },
          "url": "${SEO.url}",
          "name": "${SEO.title}",
          "image": {
            "@type": "ImageObject",
            "url": "${SEO.image}"
          },
          "headline": "${SEO.marketing_heading}",
          "keywords": "${SEO.title},${SEO.category},${SEO.deal},${SEO.suburb}",
          "description": "${SEO.description}",
          "identifier": "${SEO.url}",
          "offers": {
            "@type": "Offer",
            "category": "${SEO.category} ${SEO.deal}",
            "offeredBy": {
              "@type": "Organization",
              "telephone": "${process.env.GATSBY_COMPANY_TEL_1}",
              "name": "${process.env.GATSBY_COMPANY_NAME}",
              "logo":{
                "@type": "ImageObject",
                "url": "${process.env.GATSBY_COMPANY_LOGO_SQUARE}"
              },
              "address": {
                "@type": "PostalAddress",
                "addressCountry": "${process.env.GATSBY_COMPANY_COUNTRY}",
                "addressLocality": "${process.env.GATSBY_COMPANY_SUBURB}",
                "addressRegion": "${process.env.GATSBY_COMPANY_CITY}, ${process.env.GATSBY_COMPANY_PROVINCE}",
                "streetAddress": "${process.env.GATSBY_COMPANY_ADDRESS}"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "Letting and sales contact",
                "name": "${SEO.contact_name}",
                "email": "${SEO.contact_email}",
                "telephone": "${SEO.contact_telephone}"
              }
            },
            "price": ${SEO.price},
            "priceCurrency": "${process.env.GATSBY_CURRENCY_CODE}",
            "sku": "${SEO.id}"
          },
          "provider":{
            "@type": "Organization",
            "name": "${process.env.GATSBY_COMPANY_NAME}",
            "logo":{
              "@type": "ImageObject",
              "url": "${process.env.GATSBY_COMPANY_LOGO_SQUARE}"
            }
          }
        }
      ]
    }`
  }

  return (

    <Fragment>

      <Helmet>

        {SEO.siteLocale && <meta property="og:locale" content={SEO.siteLocale} />}

        {SEO.title && <title>{SEO.title + " | " + SEO.siteName}</title>}

        {SEO.siteName && <meta property="og:site_name" content={SEO.siteName} />}

        {SEO.title && <meta property="og:title" content={SEO.title + " | " + SEO.siteName} />}
        {SEO.title && <meta name="twitter:title" content={SEO.title + " | " + SEO.siteName} />}

        {SEO.description && <meta name="description" content={SEO.description} />}
        {SEO.description && <meta property="og:description" content={SEO.description} />}
        {SEO.description && <meta name="twitter:description" content={SEO.description} />}

        {SEO.keywords && <meta name="keywords" content={SEO.keywords} />}

        {SEO.image && <meta name="image" content={SEO.image} />}
        {SEO.image && <meta property="og:image" content={SEO.image} />}
        {SEO.image && <meta name="twitter:image" content={SEO.image} />}

        {SEO.image && <meta name="twitter:card" content="summary_large_image" />}

        {SEO.url && <meta property="og:url" content={SEO.url} />}

        {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}

      </Helmet>

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={jsonld}
      />

    </Fragment>

  )
}

SEOProperty.propTypes = {
  keywords: PropTypes.string,
  best_image: PropTypes.string,
  street_address: PropTypes.string,
  suburb: PropTypes.string,
  city: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  url: PropTypes.string,
  property_name: PropTypes.string,
  marketing_heading: PropTypes.string,
  marketing_description: PropTypes.string,
  id: PropTypes.string,
  deal: PropTypes.string,
  price: PropTypes.number,
}

export default SEOProperty;

const query = graphql`
  query SEOProperty {
    site {
      siteMetadata {
        siteLocale
        siteName
        siteTitle
        siteKeywords
        siteDescription
        siteUrl
        siteImage
        twitterUsername
      }
    }
  }
`