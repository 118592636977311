import styled from 'styled-components';

export const StyledButton1 = styled.div`

  font-family: ${({ theme }) => theme.secondaryTypeface};
  font-weight: 300;
  font-size: 0.8rem;
  cursor: pointer;
  text-transform: uppercase;

  position: absolute;
  right: 0;
  bottom: 0;

  max-width: 66%;
  min-width: 200px;
  color: ${({ theme }) => theme.primaryWhite};
  background: ${props => props.color};
  border: none;
  display: grid;
  grid-template-columns: 75% 25%;
  
  text-align: center;

  white-space: pre;

  .buttonSquare {
    margin-top: auto;
    margin-bottom: auto;
  }

  .buttonText {
    padding: 1rem;
  }


  svg {
    width: 80%;
    margin: auto;
    font-size: 1.2rem;
  }

  .bold {
    font-weight: 600;
  }

  @media(max-width: 577px){
    position: relative;
    margin-top: 1rem;
  }
`

export const StyledButton2 = styled.div`

  font-family: ${({ theme }) => theme.secondaryTypeface};
  font-weight: 300;
  font-size: 0.8rem;
  cursor: pointer;
  text-transform: uppercase;

  max-width: 66%;
  min-width: 200px;
  margin-left: auto;
  
  border: none;
  display: grid;
  grid-template-columns: 75% 25%;
  
  text-align: center;

  white-space: pre;

  .buttonText {
    border-right: 1px solid black;
    padding: 1rem;
  }

  .buttonSquare {
    color: ${({ theme }) => theme.primaryWhite};
    background-color: ${({ theme }) => theme.primaryDark};
  }

  svg {
    height: 100%;
    margin: auto;
    font-size: 1.2rem;
  }

  .bold {
    font-weight: 600;
  }
`

export const StyledButton3 = styled.div`

  font-family: ${({ theme }) => theme.secondaryTypeface};
  font-weight: 300;
  font-size: 0.8rem;
  cursor: pointer;
  text-transform: uppercase;

  max-width: 66%;
  min-width: 200px;
  
  border: none;
  display: grid;
  grid-template-columns: 75% 25%;
  
  text-align: center;

  white-space: pre;

  .buttonText {
    padding: 1rem;
  }

  .buttonSquare {
    margin-top: auto;
    margin-bottom: auto;
  }

  svg {
    height: 100%;
    margin: auto;
    font-size: 1.2rem;
  }

  .bold {
    font-weight: 600;
  }
`

export const StyledButton4 = styled.div`

  font-family: ${({ theme }) => theme.secondaryTypeface};
  font-weight: 300;
  font-size: 0.8rem;
  cursor: pointer;
  text-transform: uppercase;

  margin-bottom: 3rem;

  max-width: 100px;

  .buttonText {
   
  }

  .buttonSquare {
    margin-top: auto;
    margin-bottom: auto;
  }

  svg {
    height: 100%;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }

  .bold {
    font-weight: 600;
  }

  @media(max-width: 576px) {
    display: none;
  }
`

export const StyledButton8 = styled.div`

font-family: ${({ theme }) => theme.secondaryTypeface};
  font-weight: 300;
  font-size: 0.8rem;
  cursor: pointer;
  text-transform: uppercase;

  margin: auto!important;
  svg:hover {
    color: white;
  }

`