import styled from 'styled-components';

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border-radius: 3px;
  border: none;
  padding: 0;
  z-index: 999999;
  margin: 4px 0;
  text-align: center;

  @media(min-width: 667px){
    
  }

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 2px;
    background: white;
    border-radius: 0;

    transition: all 0.3s ease-in-out;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
      
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
   
    }
  }

  .burgerMiddle {
    margin-left: auto;
    margin-right: auto;
  }

  @media(max-width: 666px){
    div {
      background: ${({ theme, open }) => theme.primaryWhite};
    }
    margin: 1rem auto;
  }

  .arrowHidden {
    visibility: hidden;
  }

  .arrowTop {
    transform: rotate(45deg)!important;
    width: 16.5px;
    margin-top: ${({ open1 }) => open1 ? '5px' : '0'};
  }

  .arrowTop1 {
    transform: rotate(45deg)!important;
    width: 16.5px;
    margin-top: ${({ open2 }) => open2 ? '5px' : '0'};
  }

  .arrowBottom {
    transform: rotate(-45deg)!important;
    width: 16.5px;
    margin-left: ${({ open1 }) => open1 ? '11px' : '0'};
    margin-bottom: ${({ open1 }) => open1 ? '11px' : '0'};
  }

  .arrowBottom1 {
    transform: rotate(-45deg)!important;
    width: 16.5px;
    margin-left: ${({ open2 }) => open2 ? '11px' : '0'};
    margin-bottom: ${({ open2 }) => open2 ? '11px' : '0'};
  }

  .mainIcon {
    width: 100%;
    height: 100%;
    
    //transform: ${({ open3 }) => open3 ? 'rotate(45deg)!important' : 'none'};
    background: ${({ open3 }) => open3 ? 'white!important' : 'transparent'};
    //transform-origin: ${({ open3 }) => open3 ? '50%' : '0'};
    display: grid;
    grid-template-columns: calc(50% - 1px) calc(50% - 1px);
    grid-column-gap: 2px;
    grid-template-rows: calc(50% - 1px) calc(50% - 1px);
    grid-row-gap: 2px;
    background: transparent;
  }

  .mainIcon .squares {
    
    background: ${({ open3, theme }) => open3 ? theme.primaryDark : 'white'};
    height: initial;
    width: initial;
  }

`;