import React, { useState, useRef } from "react";
import { FaInstagram, FaLinkedin, FaFacebookSquare } from 'react-icons/fa';

import { BottomBanner, Modal, ContactForm, SEOFooter, Maps } from ".";

import { useOnClickOutside } from '../hooks';

import { StyledFooter, StyledPage, Grid34, Grid32, Grid22, Grid24 } from "./css";

// import ReviewData from "reviews-plugin/src/components/reviews";

const { formatTelephone } = require("./utils/telFormatter");

export default function Footer() {

  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  return (

    <StyledFooter>


      {/* <div ref={node}>

        <Modal show={open} handleClose={() => setOpen(false)}>

          <ContactForm data={`Please contact me so we can go through your offering.`} />

        </Modal>

      </div> */}



      <div className="middleFooter" id="contact">

        <StyledPage>

          <Grid34>

            <Maps lat={parseFloat(process.env.GATSBY_COMPANY_LATITUDE)} lng={parseFloat(process.env.GATSBY_COMPANY_LONGITUDE)} />

            <ContactForm data={`Please contact me so we can go through your offering.`} />

            <div>
              <p>Address: {process.env.GATSBY_COMPANY_ADDRESS}, {process.env.GATSBY_COMPANY_SUBURB}, {process.env.GATSBY_COMPANY_CITY}</p>
              <p>Email: {process.env.GATSBY_COMPANY_EMAIL}</p>
              <p>Telephone: {process.env.GATSBY_COMPANY_TEL_1}</p>
              <Grid32>

                <span>
                  <a href={process.env.GATSBY_INSTAGRAM_PAGE} target="_blank" rel="noreferrer">
                    <FaInstagram />
                  </a>
                </span>

                <span>
                  <a href={process.env.GATSBY_FACEBOOK_PAGE} target="_blank" rel="noreferrer">
                    <FaFacebookSquare />
                  </a>
                </span>

                <span>
                  <a href={process.env.GATSBY_LINKEDIN_PAGE} target="_blank" rel="noreferrer">
                    <FaLinkedin />
                  </a>
                </span>

              </Grid32>
            </div>

          </Grid34>


        </StyledPage>

      </div>

      <div className="bottomFooter">

        <div className="d9Signature">
          <a href="https://d9cre.com" title="Website built by D9 CRE" target="_blank" rel="noreferrer"><img src="https://res.cloudinary.com/d9-cre/image/upload/v1598599829/vhe6cvjohpmxr0qqbokg.svg" alt="D9 CRE" width="30px" /></a>
        </div>
      </div>

      {/* <SEOFooter /> */}

    </StyledFooter>

  )
}