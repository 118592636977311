import React from 'react';

import { event } from "../";

import { StyledForm } from "../css";

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

//const event = require("../").GTAG;

class ContactForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      tel: "",
      company: "",
      message: this.props.data,
      contacts: []
    };
  }

  handleSubmit = e => {
    e.preventDefault();

    if (this.state.name === "") {

      alert("Please fill in your name");

    } else if (this.state.email === "") {

      alert("Please fill in a valid email address");

    } else {

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': e.target.getAttribute('name'),
          'contacts': this.state.contacts,
          ...this.state,
        }),
      })
        .then(() => alert("Message successfully sent!"))
        .catch(error => alert(error));

      event({
        category: 'Contact',
        action: 'contact_form_submission',
        label: this.props.data
      });
    }

  };

  handleChange = e => this.setState({
    [e.target.name]: e.target.value,
    contacts: this.props.contacts && this.props.contacts.length > 1 ? `${this.props.contacts[0].email}, ${this.props.contacts[1].email}` : this.props.contacts && this.props.contacts.length > 0 ? this.props.contacts[0].email : []
  });

  render() {

    const { name, email, tel, company } = this.state;

    return (

      <StyledForm>

        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <p hidden>
            <label>
              Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
            </label>
          </p>
          <input type="hidden" name="form-name" value="contact" />
          <input aria-label="bot" type="hidden" name="bot-field" />

          <input className="formInputBox odd" aria-label="name" type="text" name="name" id="name" placeholder="Name" value={name} onChange={this.handleChange} />
          <input className="formInputBox" aria-label="email" type="email" name="email" id="email" placeholder="Email" value={email} onChange={this.handleChange} />
          <input className="formInputBox odd" aria-label="tel" type="tel" name="tel" id="tel" placeholder="Tel eg. 08212345678" value={tel} onChange={this.handleChange} />
          <input className="formInputBox" aria-label="company" type="text" name="company" id="company" placeholder="Company" value={company} onChange={this.handleChange} />

          <input type="hidden" name="contacts" value={this.state.contacts} />

          <textarea aria-label="message" name="message" id="message" rows="5" defaultValue={this.props.data} onChange={this.handleChange} />

          <button aria-label="submit" type="submit">Send</button>
          {/* <button aria-label="reset" type="reset">Clear</button> */}

        </form>

      </StyledForm >

    )
  }
}

export default ContactForm