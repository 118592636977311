import styled from 'styled-components';

export const StyledTable = styled.div`

    width: 900px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

  h5 {
    text-align: center;
    margin-bottom: 1em;
  }

  table {
    max-width: 100%;
    max-height: 30%;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;
  }

  thead {
    background: #eee;
  }

  thead tr th {
    padding: 0.5rem;
    font-family: ${({ theme }) => theme.secondaryTypeface};
    font-weight: 600;
  }

  th, td {
    padding: 5px 10px 5px 0;
    text-align: center;
    white-space: nowrap;
  }

  @media(max-width: 666px){
    table {
      display: block;
    }
  }

`;
