import styled from 'styled-components';

export const StyledBreadcrumb = styled.div`
  position: absolute;
  top: -2rem;
  left: 0;

  .breadContainer {
    max-width: 300px;
  }
  
  .breadLine {
    min-width: 300px;
    background-color: ${({ theme }) => theme.primaryWhite};
    padding: 1rem 2rem;
  }

  ul {
    list-style: none;
  }

  li {
    display: inline-block;
    padding: 0 0.5rem;
    color: ${({ theme }) => theme.primaryLight};
  }

  a {
    color: ${({ theme }) => theme.primaryLight};
    &:hover {
      color: ${({ theme }) => theme.primaryFont};
    }
  }

  .active {
    color: ${({ theme }) => theme.primaryFont};
  }

`;
