import styled from 'styled-components';

export const StyledBio = styled.div`

  margin: 2em auto;

  .socialIconRow {
    margin-top: 1rem;
  }

  h4 {
    margin-top: 3rem;
    margin-bottom: 0;
  }

`;
